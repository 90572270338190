import React from "react";
import Directory from "../../components/Directory";
import "./styles.scss";

const Homepage = () => {
    return (
        <section className="homepage">
            <Directory></Directory>
        </section>
    )
}

export default Homepage;
