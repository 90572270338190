import "./styles.scss"

const PageNotFound = () => {
    return (
        <div className="title">☹
            <div className="title">404
                <h1 className="subtitle">Pagina nu există!</h1>
            </div>
        </div>
    );

}

export default PageNotFound;