import { React } from "react";
import { Link } from "react-router-dom";
import "./styles.scss"

import Logo from "./../../assets/logo.png"


const Header = () => {
    return (
        <header className="header">
            <div className="wrap">
                <div className="logo">
                    <Link to={"/"}>
                        <img src={Logo} alt="alerte-stoc logo" />
                    </Link>
                </div>

                <div className="callToActions">
                    <ul>
                        <li>
                            <a href={"mailto:contact@alerte-stoc.ro"}>
                                Contact
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    )
}

export default Header;
