import { React } from "react";
import { Route, Routes } from "react-router-dom";

// layouts
import MainLayout from "./layouts/MainLayout";
import HomepageLayout from "./layouts/HomepageLayout";

// pages
import Homepage from "./pages/Homepage";
import Search from "./pages/Search";
import ProductRedirect from "./pages/ProductRedirect";
import Console from "./pages/ProductRedirect/Console";
import PlaciVideo from "./pages/ProductRedirect/PlaciVideo";
import PageNotFound from "./pages/PageNotFound";

import "./default.scss";

const App = () => {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomepageLayout><Homepage /></HomepageLayout>}></Route>
        <Route path="/categories/:filterType" element={<MainLayout><Search /></MainLayout>}></Route>
        <Route path="/c/:productId" element={<ProductRedirect category="console"></ProductRedirect>}></Route>
        <Route path="/v/:productId" element={<ProductRedirect category="placi-video"></ProductRedirect>}></Route>
        <Route path="/a/:productId" element={<ProductRedirect category="resigilate-apple"></ProductRedirect>}></Route>
        <Route path="/i/:productId" element={<ProductRedirect category="resigilate-iphone"></ProductRedirect>}></Route>
        <Route path="/t/:productId" element={<ProductRedirect category="resigilate-telefoane"></ProductRedirect>}></Route>
        <Route path="/l/:productId" element={<ProductRedirect category="resigilate-laptopuri"></ProductRedirect>}></Route>
        <Route path="/o/:productId" element={<ProductRedirect category="reconditionate-orange"></ProductRedirect>}></Route>
        <Route path="/f/:productId" element={<ProductRedirect category="resigilate-top-favorite"></ProductRedirect>}></Route>
        <Route path="/p/:productId" element={<ProductRedirect category="resigilate-componente"></ProductRedirect>}></Route>
        <Route path="/u/:productId" element={<ProductRedirect category="resigilate-tv"></ProductRedirect>}></Route>
        <Route path="/e/:productId" element={<ProductRedirect category="resigilate-electrocasnice"></ProductRedirect>}></Route>
        <Route path="/b/:productId" element={<ProductRedirect category="resigilate-bricolaj"></ProductRedirect>}></Route>
        <Route path="/console" element={<Console />}></Route>
        <Route path="/placi_video" element={<PlaciVideo />}></Route>
        <Route path="*" element={<HomepageLayout><PageNotFound /></HomepageLayout>}></Route>
      </Routes>
    </div >
  );
}

export default App;
