import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductUrlStart } from "../../redux/Products/products.actions";

const mapState = state => ({
    productUrl: state.productsData.productUrl
})

const ProductRedirect = (props) => {
    const dispatch = useDispatch();
    const { productId } = useParams();
    const { productUrl } = useSelector(mapState);
    const category = props.category;
    const urlData = { productId, category };

    dispatch(
        fetchProductUrlStart(urlData)
    )

    useEffect(() => {
        if (productUrl !== "") {
            window.location.replace(productUrl);
        }
    }, [productUrl]);
    return (
        null
    )
}

export default ProductRedirect;
