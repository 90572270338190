import React from "react";
import "./styles.scss";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="wrap">
                © {(new Date().getFullYear())} alerte-stoc
            </div>
        </footer>
    )
}

export default Footer;
