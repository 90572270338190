import productsTypes from "./products.types";

export const fetchProductsStart = (filters = {}) => ({
    type: productsTypes.FETCH_PRODUCTS_START,
    payload: filters
});

export const setProducts = products => ({
    type: productsTypes.SET_PRODUCTS,
    payload: products
});

export const fetchProductUrlStart = urlData => ({
    type: productsTypes.FETCH_PRODUCT_URL_START,
    payload: urlData
});

export const setProductUrl = productUrl => ({
    type: productsTypes.SET_PRODUCT_URL,
    payload: productUrl
});
