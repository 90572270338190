import { firestore } from "../../firebase/utils";

export const handleFetchProducts = ({ filterType, startAfterDoc, persistProducts = [] }) => {
    return new Promise((resolve, reject) => {
        const pageSize = 10;

        let ref = firestore.collection(filterType).orderBy("stock", "desc").orderBy("lastModified", "desc").limit(pageSize);

        if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

        ref
            .get()
            .then(snapshot => {
                const totalCount = snapshot.size;

                const data = [
                    ...persistProducts,
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            documentID: doc.id
                        }
                    })
                ];

                resolve({
                    data,
                    queryDoc: snapshot.docs[totalCount - 1],
                    isLastPage: totalCount < 1
                });
            })
            .catch(err => {
                reject(err);
            })
    })
}

export const handleFetchProductUrl = (urlData) => {
    return new Promise((resolve, reject) => {
        const { productId, category } = urlData;
        firestore
            .collection(category)
            .doc(productId)
            .get()
            .then(snapshot => {

                if (snapshot.exists) {
                    // let resolve_ = "";
                    // if (snapshot.data().store === "eMAG") {
                    //     resolve_ = snapshot.data().url;
                    // } else {
                    //     resolve_ = snapshot.data().affiliate;
                    // }
                    let resolve_ = snapshot.data().url;
                    resolve(
                        resolve_
                    );
                }
            })
            .catch(err => {
                reject(err);
            })
    })
}
