import { takeLatest, put, all, call } from "redux-saga/effects";
import productsTypes from "./products.types";
import { setProducts, setProductUrl } from "./products.actions";
import { handleFetchProducts, handleFetchProductUrl } from "./products.helpers";


export function* fetchProducts({ payload }) {
    try {
        const products = yield handleFetchProducts(payload);
        yield put(
            setProducts(products)
        );
    } catch (err) {
        // console.log(err);
    }
}

export function* onFetchProductsStart() {
    yield takeLatest(productsTypes.FETCH_PRODUCTS_START, fetchProducts)
}

export function* fetchProductUrl({ payload }) {
    try {
        const productUrl = yield handleFetchProductUrl(payload);
        yield put(
            setProductUrl(productUrl)
        );
    } catch (err) {
        // console.log(err);
    }
}

export function* onFetchProductUrlStart() {
    yield takeLatest(productsTypes.FETCH_PRODUCT_URL_START, fetchProductUrl)
}

export default function* productSagas() {
    yield all([
        call(onFetchProductsStart),
        call(onFetchProductUrlStart)
    ])
}
