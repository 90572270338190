import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchProductsStart } from "../../redux/Products/products.actions";
import Product from "./Product";
import FormSelect from "../forms/FormSelect";
import LoadMore from "../LoadMore";
import "./styles.scss";

const mapState = ({ productsData }) => ({
    products: productsData.products
});

const ProductResults = () => {
    return (<></>);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { filterType } = useParams();
    const { products } = useSelector(mapState);

    const { data, queryDoc, isLastPage } = products;

    useEffect(() => {
        // if (!window.location.href.includes("alerte-stoc.ro")) window.location.href = `https://alerte-stoc.ro/categories/${filterType}`
        dispatch(
            fetchProductsStart({ filterType })
        )
        // eslint-disable-next-line
    }, [filterType]);

    const handleFilters = (e) => {
        const nextFilter = e.target.value;
        navigate(`/categories/${nextFilter}`)

    };

    if (!Array.isArray(data)) return null;

    if (data.length < 1) {
        return (
            <div className="products">
                <p>
                    Nu s-au găsit rezultate.
                </p>
            </div>
        );
    }

    const configFilters = {
        defaultValue: filterType,
        options: [{
            name: "🎮 Oferte console",
            value: "console"
        }, {
            name: "⚡ Oferte plăci video",
            value: "placi-video"
        }, {
            name: "❤️ Oferte top favorite resigilate",
            value: "resigilate-top-favorite"
        }, {
            name: "📱 Oferte iPhone 15 Pro / Pro Max resigilate",
            value: "resigilate-iphone"
        }, {
            name: "🍎 Oferte produse Apple resigilate",
            value: "resigilate-apple"
        }, {
            name: "📺 Oferte TV resigilate",
            value: "resigilate-tv"
        }, {
            name: "💻 Oferte laptopuri resigilate",
            value: "resigilate-laptopuri"
        }, {
            name: "📱 Oferte telefoane resigilate",
            value: "resigilate-telefoane"
        }, {
            name: "🖥️ Oferte componente PC resigilate",
            value: "resigilate-componente"
        }, {
            name: "🔌 Oferte electrocasnice resigilate",
            value: "resigilate-electrocasnice"
        }, {
            name: "🚜 Oferte bricolaj resigilate",
            value: "resigilate-bricolaj"
        }
        ],
        handleChange: handleFilters,
    };

    const handleLoadMore = () => {
        dispatch(
            fetchProductsStart({
                filterType,
                startAfterDoc: queryDoc,
                persistProducts: data
            })
        )
    };

    const configLoadMore = {
        onLoadMoreEvt: handleLoadMore,
    };

    return (
        <div className="products">
            <div className="wrap-collabsible">
                <input id="collapsible" className="toggle" type="checkbox"></input>
                <label htmlFor="collapsible" className="lbl-toggle">💥OFERTELE DE AZI</label>
                <div className="collapsible-content">
                    <div className="content-inner">
                        <ul>
                            <li>
                                <a href="https://www.emag.ro/nav/deals">eMAG</a>
                            </li>
                            <li>
                                <a href="https://event.2performant.com/events/click?ad_type=quicklink&aff_code=ce6f5e60b&unique=ea7857c9c&redirect_to=https%253A//www.flanco.ro/campanie/preturi-mici">Flanco</a>
                            </li>
                            <li>
                                <a href="https://event.2performant.com/events/click?ad_type=quicklink&aff_code=ce6f5e60b&unique=d4f678b43&redirect_to=https%253A//www.evomag.ro/pagini/oferte/">evoMAG</a>
                            </li>
                            <li>
                                <a href="https://www.forit.ro/promotii/">ForIT</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <FormSelect {...configFilters}></FormSelect>

            <div className="productResults">
                {data.map((product, pos) => {
                    const { documentID, name, thumbnail, price, stock, store, url, affiliate, description } = product;
                    if (!documentID || !name || !thumbnail || !price || !store || !url ||
                        !affiliate || !description || typeof (stock) != "boolean") return null;

                    const configProduct = {
                        ...product
                    };

                    return (
                        <Product key={pos} {...configProduct}></Product>
                    )
                })}

            </div>
            {!isLastPage && (
                <LoadMore {...configLoadMore} />
            )}
        </div>
    );
};

export default ProductResults;
